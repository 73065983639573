import React from "react";
import { graphql } from "gatsby";
import SeO from "../components/seo";

const IndexPage = ( {data} ) => (
  <React.Fragment>
    <SeO title="Αρχική" description="Αρχική Σελίδα" />
    <div className="welcome" style={{textAlign: 'justify', textJustify: 'inter-word'}} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    
  </React.Fragment>
)

export const query = graphql`
    query {
        markdownRemark(frontmatter: {title: {eq: "Καλωσήλθατε"}}){
            frontmatter{
              title
              date
            }
            html
        }
    }
`

export default IndexPage
